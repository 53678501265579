.Image-upload-img {
    width: 70px;
    height: 70px;
}

.Image-upload-container {
    width: 100px !important;
    height: 100px;
    font-size: 10px;
}

@media only screen and (min-width: 1024px) {
    .Image-upload-container {
        width: 140px !important;
        height: 140px;
        font-size: 13px;
    }
    .Image-upload-img {
        width: 110px;
        height: 110px;
    }
}
  
@media only screen and (min-width: 1280px) {
    .Image-upload-container {
        width: 180px !important;
        height: 180px;
        font-size: 16px;
    }.Image-upload-img {
        width: 150px;
        height: 150px;
    }
}
