
.dropdown {
    /* background-color: orange; */
    text-align: center;
}

.dropdown-options-list {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1px 0;
}

.dropdown-option {
    text-align: center;
    font-size: 10px;
    width: 30px;
    background-color: whitesmoke;
    padding: 1px;
    margin: 2px;
    /* background-color: pink; */
}

.dropdown-option:hover {
    background-color: darkslategray;
}

.Icon__ThreeDots {
    max-width: 10px;
}

@media only screen and (min-width: 1024px) {
    .Icon__ThreeDots {
        max-width: 12px;
    }
    .dropdown-option {
        /* background-color: green; */
        font-size: 11px;
        /* width: 55x; */
    }
}
    
@media only screen and (min-width: 1280px) {
    .Icon__ThreeDots {
        max-width: 20px;
    }
    .dropdown-option {
        /* background-color: yellow; */
        font-size: 14px;
        width: 50px;
    }
}


    
@media only screen and (min-width: 1500px) {
    .Icon__ThreeDots {
        max-width: 20px;
    }
    .dropdown-option {
        /* background-color: orangered; */
        font-size: 16px;
        width: 80px;
    }
}