
#add-new-tag-window {
  display: none;
}

#capture-screen-window {
  display: none;
}

.tab-unit-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
}


/********************** INSTRUCTION **********************/

.justify {
  text-align: justify;
}

p {

  font-size: 0.95vw;

}

h3 {

  font-size: 1.6vw;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em
}

strong {
  font-size: 1vw;
}

/********************** NOTES **********************/

.Scene__Notes__Container {
  /* background-color: pink; */
  margin-top: 1%;
  text-align: justify;
  width: 100%;
  height: 19%;
  overflow: hidden;
}

.Student__Notes__Container {
  /* background-color: orange; */
  display: block;
  text-align: justify;
  width: 100%;
  height: 67%;
  padding: 0;
}




.Notes__Button__Container {
  /* background-color: green; */
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
}

textarea {
  font-size: 0.75vw;
  padding: 1em;
  width: 95.7%;
  height: 80%;
  resize:none;
  overflow-y: scroll;
  border: none !important; 
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: auto;
  outline: none;
}

.student-notes-button {
  margin: auto 0;
  font-size: 1.1vw;
  width: 100%;
  height: 80%;
}

.student-notes-button:hover {
  background-color: darkslategray;
}


/********************** SETTINGS **********************/

.tab-settings-wrapper {
    /* background-color: greenyellow; */
    width: 100%;
    padding: 2% 5%;
}

.tab-settings-body {
    /* background-color: gold; */
    height: 80%;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.tab-settings-left {
  /* background-color: aquamarine; */
  width: 47.5%;
}

.tab-settings-right {
  width: 47.5%;
  /* background-color: navy; */
}

.setting-container {
    /* background-color: pink; */
    display: flex;
    justify-content: left;
    align-items: center;
    height: 10%;
    margin: 3px;
}

select {
  width: 100%;
}

input {
  width: 100%;
}

.settings-button {
  width: 100%;
  height: 100%;
  font-size: 1vw;
}

.settings-button:hover {
  background-color: darkslategray;
}

.settings-title {
  font-size: 1.3vw
}

/********************** EVIDENCES **********************/

.evidence-buttons-container {
  /* background-color: pink; */
  width: 100%;
  height: 15%;
  padding: 0 1.5%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.evidence-cards-container {
  /* background-color: yellowgreen; */
  width: 100%;
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
}

.button-evidence {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 32%;
  height: 80%;
  padding: 5px;
  padding-right: 6%;
  border-radius: 3px;
  border-style: none;
  font-size: 1vw;
}

.button-evidence:hover {
  background-color: rgb(82, 77, 77);
  border-style: none;
}

.button-icon {
  margin: 2px;
  height: 60%;
  width: auto;
}

/*Scroll Bar*/

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #555; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
