.Card__Container {
    background-color: whitesmoke;
    width: 95%;
    height: 20%;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    padding: 2.5px;
}

.Card__Container:hover {
    background-color: rgb(82, 77, 77);
}

.Card__Navigate__Button {
    background-color: rgb(0,0,0,0);
    /* background-color: red; */
    border-style: none;
    width: 95%;
    display: flex;
}

.Card__Left {
    /* background-color: yellow; */
    width: 15%;
    height: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Card__Pin {
    width: 100%;
    height: 100%;
}

.Card__Image {
    width: 100%;
    height: 100%;
}

.Card__Right {
    /* background-color: yellowgreen; */
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Card__Title {
    /* background-color: cyan; */
    color: black;
    text-align: left;
    border-bottom: solid 1px;
    margin: 0;
}

.Card__Description{
    font-size: 10px;
    text-align: left;
    /* background-color: pink; */
    max-height: 28px;
    overflow : hidden;
    height: 60%;
    margin: 0;
}

.Card__Description__p {
    margin: 0;
}
  
.Card__Menu {
    /* background-color: cyan; */
    width: 8%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items:flex-start ;
    padding: 1% 0 0 0;
}

.Icon__Menu {
    width: 100%;
    max-width: 20px;
    min-width: 10px;
    height: auto;
}

.button2 {
    background-color: transparent;
    width: 100%;
    height: 50%;
    border: none;
    border-radius: 50%;
}
  
.button2:hover {
    background-color: whitesmoke;
}

textarea {
    font-family:'Tahoma';
}
