/* Ratio of 16:9 - 0.5625 */

.Panel {
  margin-inline: auto;
  display: flex;
  /* width: 800px;
  height: 450px; */
  width: 99vw;
  height: 99.5vh;
}

/* Ratio of 16:9 - 0.5625 */
.Panel__Left {
  background-color: black;
  /* width: 56.25%; */
  width: 60%;
  padding: 2.5px;
}

.Panel__Right {
  background-color: black;
  width: 40%;
  /* width: 43.75%; */
}