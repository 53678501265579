html, button, p, h1, h2, h3, h4, h5, h6 {
  font-family: "Calibri", "Georgia", Times, serif;
}

html {
  /* background-color: black; */
}

.App {
  /* background-color: aquamarine; */
}

html {
  background-color: black;
}

p, label, select, input {
  font-size: 0.85vw;
  margin-top: 5px;
  margin-bottom: 5px;
}