.EvidenceMenu__Container {
    background-color: rgba(0, 0, 0, 0.5);
    /* background-color: whitesmoke; */
    position: absolute;
    width: 60px;
    padding: 3px;
    display: block;
    margin-top: -5px;
    /* margin-left: 85px; */
}


.EvidenceMenu__Button {
    font-size: 11px;
    width: 100%;
    height: 14px;
    border-style: none;
}

/* @media only screen and (min-width: 1024px) {
    .EvidenceMenu__Button {
        height: 15px;
        height: 18px;
    }
  }
  
  @media only screen and (min-width: 1280px) {
    .EvidenceMenu__Button {
        height: 20px;
        height: 23px;
    }
    .EvidenceMenu__Container {
        width: 80px;
    }
  } */

.EvidenceMenu__Button:hover {
    background-color: darkslategray;
    border-style: none;
}

.add-new-tag-window {
    display: none;
}

.capture-screen-window {
    display: none;
}
/********* Evidence Edit Window Background Black **********/

.EvidenceEditWindow__Back {
    /* display: none; */
    background-color: rgba(0, 0, 0, 0.5);
    /* background-color: pink; */
    position: fixed;
    width: 100vw;
    height: 100vh;
    left:0;
    top:0;
    display: flex;
    justify-content: right;
}


/********* Evidence Edit Window Container **********/

.EvidenceEditWindow__Container {
    background-color: gray;
    margin-top: 5vh;
    width: 40vw;
    height: 50.1vh;
}

.Edit-div {
    margin-top: 5px;
    /* background-color: cyan; */
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.screenshot-wrap{
    height: 40%;
}

.Edit-div-dragdrop {
    /* background-color: cyan; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 10px 0;
}

.Edit-div-desc {
    /* background-color: cyan; */
    width: 100%;
    min-height: 30px;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 1vw 0 2vw 0;
}

.Edit-div-screenshot {
    height: 100%;
    width: auto;
}

.Edit-div-left {
    /* background-color: orange; */
    width: 25%;
    height: 100%;
    text-align: left;
}

.Edit-div-right {
    /* background-color: greenyellow; */
    width: 65%;
    height: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.Edit-input {
    padding-left: 0.85em;
    padding-right: 0.85em;
    font-size: 0.85vw;
    width: 100%;
    margin: 0;
}

.Edit-textarea {
    font-size: 0.85vw;
    width: 100%;
    height: 92%;
    margin: 0;
}

.EvidenceEditWindow__Button {
    font-size: 1vw;
    width: 48%;
    padding: 3px;
    height: 100%;
    border-style: none;
    border-radius: 5px;
}

.EvidenceEditWindow__Button:hover {
    background-color: darkslategray;
    border-style: none;
}

