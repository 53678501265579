.Inner__Panel__Left {
  width: 100%;
  height: 100%;
  position: relative;
}

.add-mattertag-button {
  background-color:  whitesmoke;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  border-style: none;
  position: absolute;
  bottom: 5%;
  left: 47.5%;
  display: none;
  /* display: flex; */
  justify-content: center;
  align-items: center; 
}

.capture-button {
  background-color:  whitesmoke;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  border-style: none;
  position: absolute;
  bottom: 5%;
  left: 47.5%;
  display: none;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.add-mattertag-button:hover {
  cursor: pointer;
  background-color:  red;
}

#add-mattertag-button:hover > p {
  color:white
}

.cam-icon {
  width: 110%;
  height: auto;
}

.plus-icon {
  font-family: arial;
  font-size : 2.5vw;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
}

.capture-button:hover {

  background-color: darkslategray; 
  cursor: pointer;

}


/* 
.plus-icon:hover {

  color: white;

} */