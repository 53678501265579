/* Wrapper for every tabs */
.tab-content-wrapper {
    background-color: gray;
    height: 50.1vh;
    overflow-y: auto;
}
  
/* Menu bar on top of right panel */
.Tab__Panel {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    height: 5%;
}

.button {
    font-size: 1.15vw;
    background-color: rgba(37, 37, 37, 0.637);
    color: whitesmoke;
    width: 25%;
    border: 2px solid darkslategray;
}

.button:hover {
  background-color: darkslategrey;
  color: white;
}

