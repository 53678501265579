

.Floorplan__Panel {
  width: 100%;
  height: 44.2vh;
  background-color: rgb(31, 32, 35);
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-items: flex-start; */
}

.canvas {
  border: 0px solid #99a529;
}

.Floorplan__Panel__Image {
  height: 100%;
  width: 100%;
}

.Compass__Logo {
  margin: 5px;
  width: 7.5%;
  height: auto;
}

.Compass__Panel {
  display: none;
  /* background-color: pink; */
  width: 100%;
  height: 42vh;
  position: relative;
  bottom: 42vh;
  display: none;
  align-items: flex-start;
  justify-content: right;
  margin-inline: auto;
}

#grid{
  width: 40%;
  height: auto;
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  margin: 3px;
}



